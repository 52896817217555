<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aggiungi un documento di gruppo</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-if="!noPriority"
                  v-model="form.priority"
                  :items="priorityOpts"
                  value="standard"
                  label="Priorità"
                  outlined
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.reference"
                  label="Riferimento interno"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-if="!noAutoImport"
                cols="12"
              >
                <v-switch
                  v-model="form.flag_auto_import"
                  label="Importa automaticamente in ogni nuova pratica"
                  false-value="no"
                  true-value="yes"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  :color="btnColor"
                  :dark="btnDark"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GroupDocumentCreate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    noAutoImport: {
      type: Boolean,
      default: false,
    },
    noPriority: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    folderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      priorityOpts: [
        {
          text: 'Normale',
          value: 'standard',
        },
        {
          text: 'Bassa priorità',
          value: 'low',
        },
        {
          text: 'Urgente',
          value: 'high',
        },
      ],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  watch: {
    folderId(val) {
      this.form.folder_id = val
    },
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.createDocGroup(this.form)
          await this.$store.dispatch('set_alert', { msg: 'Documento creato con successo', type: 'success' })
          this.$emit('reload-data')
          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$refs.form.reset()
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
