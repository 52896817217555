<template>
  <div
    class="d-flex"
  >
    <v-btn
      v-if="!noBackButton && backButtonRoute !== this.$router.currentRoute.path"
      class="my-3"
      :to="backButtonRoute"
    >
      {{ backButtonText }}
    </v-btn>
    <v-spacer />
    <v-btn
      v-if="showReloadResource"
      :color="btnColor"
      :dark="btnDark"
      rounded
      class="my-3 mx-2 hidden-xs-only"
      :loading="loadingReloadResource"
      @click="clickReloadResource"
    >
      <v-icon>mdi-reload</v-icon>
    </v-btn>
    <v-btn
      v-if="isOwner"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 mx-2 hidden-xs-only"
      @click="clickCreateFolder"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddFolder }}
    </v-btn>
    <v-btn
      v-if="isOwner"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 hidden-xs-only"
      @click="clickCreateDocument"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddDocument }}
    </v-btn>
    <v-btn
      v-if="isOwner"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 hidden-sm-and-up"
      block
      @click="clickCreateDocument"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddDocument }}
    </v-btn>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GroupDocumentsHeader',
  props: {
    showReloadResource: {
      type: Boolean,
      default: false,
    },
    loadingReloadResource: {
      type: Boolean,
      default: false,
    },
    backButtonText: {
      type: String,
      default: 'Torna allo spazio condiviso',
    },
    backButtonRoute: {
      type: String,
      default: '/plugin-group-documents',
    },
    noBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['partnerSettings', 'loggedUser']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    dictBtnAddFolder() {
      return this.partnerSettings?.dictionary?.procedure?.folders?.add ?? 'Cartella'
    },
    dictBtnAddDocument() {
      return this.partnerSettings?.dictionary?.procedure?.documents?.add ?? 'Documento'
    },
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
  },
  methods: {
    clickCreateDocument() {
      this.$emit('clickCreateDocument')
    },
    clickCreateFolder() {
      this.$emit('clickCreateFolder')
    },
    clickReloadResource() {
      this.$emit('clickReloadResource')
    },
  },
}
</script>
