import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2",on:{"contextmenu":function($event){$event.preventDefault();}}},[_c(VCardTitle,{staticClass:"subheading font-weight-bold info--text"},[_c('span',[_vm._v(_vm._s(_vm.sectionName)+" di "+_vm._s(_vm.loggedUserGroup.name))]),_c(VSpacer)],1),_c(VCardActions,[_c(VRow,[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"9"}},[_c(VRow,{staticClass:"match-height"},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('generic-info-avatar',{attrs:{"title":_vm.documentsCounterLabel,"total":_vm.groupDocumentsList.length,"color":"success","icon":"mdi-file-document"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('generic-info-avatar',{attrs:{"title":_vm.documentFilesCounterLabel,"total":_vm.totalDocumentFiles,"color":"info","icon":"mdi-file"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }